var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "section",
    { staticClass: "invoice-preview-wrapper" },
    [
      _c(
        "b-alert",
        { attrs: { variant: "danger", show: _vm.invoiceData === undefined } },
        [
          _c("h4", { staticClass: "alert-heading" }, [
            _vm._v(" Error fetching invoice data ")
          ]),
          _c(
            "div",
            { staticClass: "alert-body" },
            [
              _vm._v(" No invoice found with this invoice id. Check "),
              _c(
                "b-link",
                {
                  staticClass: "alert-link",
                  attrs: { to: { name: "apps-invoice-list" } }
                },
                [_vm._v(" Invoice List ")]
              ),
              _vm._v(" for other invoices. ")
            ],
            1
          )
        ]
      ),
      _vm.invoiceData
        ? _c(
            "b-row",
            { staticClass: "invoice-preview" },
            [
              _c(
                "b-col",
                { attrs: { cols: "12", xl: "9", md: "8" } },
                [
                  _c(
                    "b-card",
                    {
                      staticClass: "invoice-preview-card",
                      attrs: { "no-body": "" }
                    },
                    [
                      _c(
                        "b-card-body",
                        { staticClass: "invoice-padding pb-0" },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-content-between flex-md-row flex-column invoice-spacing mt-0"
                            },
                            [
                              _c("div", [
                                _c(
                                  "div",
                                  { staticClass: "logo-wrapper" },
                                  [
                                    _c("logo"),
                                    _c(
                                      "h3",
                                      {
                                        staticClass: "text-primary invoice-logo"
                                      },
                                      [_vm._v(" Vuexy ")]
                                    )
                                  ],
                                  1
                                ),
                                _c("p", { staticClass: "card-text mb-25" }, [
                                  _vm._v(
                                    " Office 149, 450 South Brand Brooklyn "
                                  )
                                ]),
                                _c("p", { staticClass: "card-text mb-25" }, [
                                  _vm._v(" San Diego County, CA 91905, USA ")
                                ]),
                                _c("p", { staticClass: "card-text mb-0" }, [
                                  _vm._v(
                                    " +1 (123) 456 7891, +44 (876) 543 2198 "
                                  )
                                ])
                              ]),
                              _c("div", { staticClass: "mt-md-0 mt-2" }, [
                                _c("h4", { staticClass: "invoice-title" }, [
                                  _vm._v(" Invoice "),
                                  _c(
                                    "span",
                                    { staticClass: "invoice-number" },
                                    [_vm._v("#" + _vm._s(_vm.invoiceData.id))]
                                  )
                                ]),
                                _c(
                                  "div",
                                  { staticClass: "invoice-date-wrapper" },
                                  [
                                    _c(
                                      "p",
                                      { staticClass: "invoice-date-title" },
                                      [_vm._v(" Date Issued: ")]
                                    ),
                                    _c("p", { staticClass: "invoice-date" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.invoiceData.issuedDate) +
                                          " "
                                      )
                                    ])
                                  ]
                                ),
                                _c(
                                  "div",
                                  { staticClass: "invoice-date-wrapper" },
                                  [
                                    _c(
                                      "p",
                                      { staticClass: "invoice-date-title" },
                                      [_vm._v(" Due Date: ")]
                                    ),
                                    _c("p", { staticClass: "invoice-date" }, [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.invoiceData.dueDate) +
                                          " "
                                      )
                                    ])
                                  ]
                                )
                              ])
                            ]
                          )
                        ]
                      ),
                      _c("hr", { staticClass: "invoice-spacing" }),
                      _vm.invoiceData.client
                        ? _c(
                            "b-card-body",
                            { staticClass: "invoice-padding pt-0" },
                            [
                              _c(
                                "b-row",
                                { staticClass: "invoice-spacing" },
                                [
                                  _c(
                                    "b-col",
                                    {
                                      staticClass: "p-0",
                                      attrs: { cols: "12", xl: "6" }
                                    },
                                    [
                                      _c("h6", { staticClass: "mb-2" }, [
                                        _vm._v(" Invoice To: ")
                                      ]),
                                      _c("h6", { staticClass: "mb-25" }, [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.invoiceData.client.name
                                            ) +
                                            " "
                                        )
                                      ]),
                                      _c(
                                        "p",
                                        { staticClass: "card-text mb-25" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.invoiceData.client.company
                                              ) +
                                              " "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "p",
                                        { staticClass: "card-text mb-25" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.invoiceData.client.address
                                              ) +
                                              ", " +
                                              _vm._s(
                                                _vm.invoiceData.client.country
                                              ) +
                                              " "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "p",
                                        { staticClass: "card-text mb-25" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.invoiceData.client.contact
                                              ) +
                                              " "
                                          )
                                        ]
                                      ),
                                      _c(
                                        "p",
                                        { staticClass: "card-text mb-0" },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.invoiceData.client
                                                  .companyEmail
                                              ) +
                                              " "
                                          )
                                        ]
                                      )
                                    ]
                                  ),
                                  _c(
                                    "b-col",
                                    {
                                      staticClass:
                                        "p-0 mt-xl-0 mt-2 d-flex justify-content-xl-end",
                                      attrs: { xl: "6", cols: "12" }
                                    },
                                    [
                                      _c("div", [
                                        _c("h6", { staticClass: "mb-2" }, [
                                          _vm._v(" Payment Details: ")
                                        ]),
                                        _c("table", [
                                          _c("tbody", [
                                            _c("tr", [
                                              _c(
                                                "td",
                                                { staticClass: "pr-1" },
                                                [_vm._v(" Total Due: ")]
                                              ),
                                              _c("td", [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "font-weight-bold"
                                                  },
                                                  [
                                                    _vm._v(
                                                      _vm._s(
                                                        _vm.paymentDetails
                                                          .totalDue
                                                      )
                                                    )
                                                  ]
                                                )
                                              ])
                                            ]),
                                            _c("tr", [
                                              _c(
                                                "td",
                                                { staticClass: "pr-1" },
                                                [_vm._v(" Bank name: ")]
                                              ),
                                              _c("td", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.paymentDetails.bankName
                                                  )
                                                )
                                              ])
                                            ]),
                                            _c("tr", [
                                              _c(
                                                "td",
                                                { staticClass: "pr-1" },
                                                [_vm._v(" Country: ")]
                                              ),
                                              _c("td", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.paymentDetails.country
                                                  )
                                                )
                                              ])
                                            ]),
                                            _c("tr", [
                                              _c(
                                                "td",
                                                { staticClass: "pr-1" },
                                                [_vm._v(" IBAN: ")]
                                              ),
                                              _c("td", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.paymentDetails.iban
                                                  )
                                                )
                                              ])
                                            ]),
                                            _c("tr", [
                                              _c(
                                                "td",
                                                { staticClass: "pr-1" },
                                                [_vm._v(" SWIFT code: ")]
                                              ),
                                              _c("td", [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.paymentDetails.swiftCode
                                                  )
                                                )
                                              ])
                                            ])
                                          ])
                                        ])
                                      ])
                                    ]
                                  )
                                ],
                                1
                              )
                            ],
                            1
                          )
                        : _vm._e(),
                      _c("b-table-lite", {
                        attrs: {
                          responsive: "",
                          items: _vm.invoiceDescription,
                          fields: ["taskDescription", "rate", "hours", "total"]
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "cell(taskDescription)",
                              fn: function(data) {
                                return [
                                  _c(
                                    "b-card-text",
                                    { staticClass: "font-weight-bold mb-25" },
                                    [
                                      _vm._v(
                                        " " + _vm._s(data.item.taskTitle) + " "
                                      )
                                    ]
                                  ),
                                  _c(
                                    "b-card-text",
                                    { staticClass: "text-nowrap" },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(data.item.taskDescription) +
                                          " "
                                      )
                                    ]
                                  )
                                ]
                              }
                            }
                          ],
                          null,
                          false,
                          1464498393
                        )
                      }),
                      _c(
                        "b-card-body",
                        { staticClass: "invoice-padding pb-0" },
                        [
                          _c(
                            "b-row",
                            [
                              _c(
                                "b-col",
                                {
                                  staticClass: "mt-md-0 mt-3",
                                  attrs: {
                                    cols: "12",
                                    md: "6",
                                    order: "2",
                                    "order-md": "1"
                                  }
                                },
                                [
                                  _c("b-card-text", { staticClass: "mb-0" }, [
                                    _c(
                                      "span",
                                      { staticClass: "font-weight-bold" },
                                      [_vm._v("Salesperson:")]
                                    ),
                                    _c("span", { staticClass: "ml-75" }, [
                                      _vm._v("Alfie Solomons")
                                    ])
                                  ])
                                ],
                                1
                              ),
                              _c(
                                "b-col",
                                {
                                  staticClass:
                                    "mt-md-6 d-flex justify-content-end",
                                  attrs: {
                                    cols: "12",
                                    md: "6",
                                    order: "1",
                                    "order-md": "2"
                                  }
                                },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "invoice-total-wrapper" },
                                    [
                                      _c(
                                        "div",
                                        { staticClass: "invoice-total-item" },
                                        [
                                          _c(
                                            "p",
                                            {
                                              staticClass: "invoice-total-title"
                                            },
                                            [_vm._v(" Subtotal: ")]
                                          ),
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "invoice-total-amount"
                                            },
                                            [_vm._v(" $1800 ")]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "invoice-total-item" },
                                        [
                                          _c(
                                            "p",
                                            {
                                              staticClass: "invoice-total-title"
                                            },
                                            [_vm._v(" Discount: ")]
                                          ),
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "invoice-total-amount"
                                            },
                                            [_vm._v(" $28 ")]
                                          )
                                        ]
                                      ),
                                      _c(
                                        "div",
                                        { staticClass: "invoice-total-item" },
                                        [
                                          _c(
                                            "p",
                                            {
                                              staticClass: "invoice-total-title"
                                            },
                                            [_vm._v(" Tax: ")]
                                          ),
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "invoice-total-amount"
                                            },
                                            [_vm._v(" 21% ")]
                                          )
                                        ]
                                      ),
                                      _c("hr", { staticClass: "my-50" }),
                                      _c(
                                        "div",
                                        { staticClass: "invoice-total-item" },
                                        [
                                          _c(
                                            "p",
                                            {
                                              staticClass: "invoice-total-title"
                                            },
                                            [_vm._v(" Total: ")]
                                          ),
                                          _c(
                                            "p",
                                            {
                                              staticClass:
                                                "invoice-total-amount"
                                            },
                                            [_vm._v(" $1690 ")]
                                          )
                                        ]
                                      )
                                    ]
                                  )
                                ]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      ),
                      _c("hr", { staticClass: "invoice-spacing" }),
                      _c(
                        "b-card-body",
                        { staticClass: "invoice-padding pt-0" },
                        [
                          _c("span", { staticClass: "font-weight-bold" }, [
                            _vm._v("Note: ")
                          ]),
                          _c("span", [
                            _vm._v(
                              "It was a pleasure working with you and your team. We hope you will keep us in mind for future freelance projects. Thank You!"
                            )
                          ])
                        ]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-col",
                {
                  staticClass: "invoice-actions",
                  attrs: { cols: "12", md: "4", xl: "3" }
                },
                [
                  _c(
                    "b-card",
                    [
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "ripple",
                              rawName: "v-ripple.400",
                              value: "rgba(255, 255, 255, 0.15)",
                              expression: "'rgba(255, 255, 255, 0.15)'",
                              modifiers: { "400": true }
                            },
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle.sidebar-send-invoice",
                              modifiers: { "sidebar-send-invoice": true }
                            }
                          ],
                          staticClass: "mb-75",
                          attrs: { variant: "primary", block: "" }
                        },
                        [_vm._v(" Send Invoice ")]
                      ),
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "ripple",
                              rawName: "v-ripple.400",
                              value: "rgba(186, 191, 199, 0.15)",
                              expression: "'rgba(186, 191, 199, 0.15)'",
                              modifiers: { "400": true }
                            }
                          ],
                          staticClass: "mb-75",
                          attrs: { variant: "outline-secondary", block: "" }
                        },
                        [_vm._v(" Download ")]
                      ),
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "ripple",
                              rawName: "v-ripple.400",
                              value: "rgba(186, 191, 199, 0.15)",
                              expression: "'rgba(186, 191, 199, 0.15)'",
                              modifiers: { "400": true }
                            }
                          ],
                          staticClass: "mb-75",
                          attrs: { variant: "outline-secondary", block: "" },
                          on: { click: _vm.printInvoice }
                        },
                        [_vm._v(" Print ")]
                      ),
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "ripple",
                              rawName: "v-ripple.400",
                              value: "rgba(186, 191, 199, 0.15)",
                              expression: "'rgba(186, 191, 199, 0.15)'",
                              modifiers: { "400": true }
                            }
                          ],
                          staticClass: "mb-75",
                          attrs: {
                            variant: "outline-secondary",
                            block: "",
                            to: {
                              name: "apps-invoice-edit",
                              params: { id: _vm.$route.params.id }
                            }
                          }
                        },
                        [_vm._v(" Edit ")]
                      ),
                      _c(
                        "b-button",
                        {
                          directives: [
                            {
                              name: "b-toggle",
                              rawName: "v-b-toggle.sidebar-invoice-add-payment",
                              modifiers: { "sidebar-invoice-add-payment": true }
                            },
                            {
                              name: "ripple",
                              rawName: "v-ripple.400",
                              value: "rgba(255, 255, 255, 0.15)",
                              expression: "'rgba(255, 255, 255, 0.15)'",
                              modifiers: { "400": true }
                            }
                          ],
                          staticClass: "mb-75",
                          attrs: { variant: "success", block: "" }
                        },
                        [_vm._v(" Add Payment ")]
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c("invoice-sidebar-send-invoice"),
      _c("invoice-sidebar-add-payment")
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }